import api from "../axios_service.js";

// Busca Todas Postagens Temas
export async function fetchPostagensTemas(filtros) {
  const response = await api.get(`/postagens-temas${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca uma Postagem Tema
export async function fetchPostagemTema(id) {
  const response = await api.get(`/postagens-temas/${id}`);
  return response.data.result;
}

// Atualiza uma Postagem Tema
export function putPostagemTema(id, tema) {
  let body = {};
  for (let key in tema) {
    body[key] = tema[key];
  }
  return api.put(`/postagens-temas/${id}`, body);
}

// Cria uma nova Postagem Tema
export function postPostagemTema(tema) {
  let body = {};
  for (let key in tema) {
    body[key] = tema[key];
  }
  return api.post("/postagens-temas/add", body);
}
