<template>
  <v-combobox
    v-model="selectedTema"
    ref="temaBox"
    :items="temas"
    :search-input.sync="buscaTema"
    :disabled="loadingTema"
    :rules="formRules"
    label="Tema"
    item-text="descricao"
    item-value="id"
    clearable
    return-object
    dense
    @keydown.enter="addTema"
  >
    <!--Se nao tiver nenhu dado no componente -->
    <template v-slot:no-data>
      <v-list-item v-if="!buscaTema">
        nenhum tema encontrado
      </v-list-item>
      <v-list-item v-else @click="addTema" class="my-n2 py-1">
        <span class="caption mr-3">Clique para adicionar: </span>

        <v-menu offset-x :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              v-bind="attrs"
              v-on="on"
              @click.stop
              label
              :color="tema.cor ? tema.cor : 'accent'"
              class="rounded-circle mr-2"
              height="20px"
              width="20px"
            >
            </v-sheet>
          </template>
          <v-color-picker
            hide-canvas
            hide-sliders
            show-swatches
            hide-inputs
            mode="hexa"
            v-model="selectedCor"
          ></v-color-picker>
        </v-menu>
        {{ buscaTema }}
      </v-list-item>
    </template>

    <template v-slot:selection="{ attrs, item }">
      <v-sheet
        label
        :color="item.cor"
        class="rounded-circle mr-2"
        height="20px"
        width="20px"
      >
      </v-sheet>

      {{ item.descricao }}
    </template>

    <template v-slot:item="{ index, item }">
      <v-text-field
        v-model="item.descricao"
        v-if="item.edit"
        :color="item.cor"
        @click.stop
        append-icon="mdi-content-save"
        @click:append="updateTema(item)"
      >
        <template v-slot:prepend-inner>
          <v-menu offset-y :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                v-bind="attrs"
                v-on="on"
                @click.stop
                label
                :color="item.cor"
                class="rounded-circle mr-2"
                height="20px"
                width="20px"
              >
              </v-sheet>
            </template>
            <v-color-picker
              hide-canvas
              show-swatches
              hide-sliders
              hide-inputs
              mode="hexa"
              @update:color="updateAddTemaColor(item)"
              v-model="selectedCorEdit"
            ></v-color-picker>
          </v-menu>
        </template>
      </v-text-field>
      <template v-else>
        <v-sheet
          label
          :color="item.cor"
          class="rounded-circle mr-2"
          height="20px"
          width="20px"
        >
        </v-sheet>
        {{ item.descricao }}
      </template>
      <v-spacer></v-spacer>

      <v-list-item-action>
        <v-btn icon small @click.stop.prevent="item.edit = !item.edit">
          <v-icon v-if="item.edit" small>mdi-close</v-icon>
          <v-icon v-else small>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import {
  fetchPostagensTemas,
  putPostagemTema,
  postPostagemTema,
} from "@/api/geral/midias_sociais_postagens_temas.js";
import { inputRequired } from "@/helpers/utils.js";
// import { setValue } from "vue-currency-input";

export default {
  name: "TemaField",

  props: {
    item_sync: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    temaProp: {
      type: Object,
    },
  },

  data() {
    return {
      loadingTema: false,
      temas: [],
      tema: { status: 1 },
      selectedCor: null,
      selectedCorEdit: null,
      buscaTema: null,
      selectedTema: null,
      formRules: [inputRequired],
    };
  },

  watch: {
    buscaTema() {
      if (this.selectedTema && this.selectedTema.descricao != this.buscaTema) {
        this.selectedTema = null;
      }
    },
    selectedCor() {
      if (this.selectedCor.hexa !== "#FF0000FF") {
        this.tema.cor = this.selectedCor.hex;
      }
    },
    selectedTema() {
      if (this.selectedTema) {
        this.parseValue();
      }
    },

    selectedCorEdit() {
      if (this.selectedCorEdit) {
        this.tema.cor = this.selectedCorEdit.hex;
      }
    },
  },

  methods: {
    parseValue() {
      this.$emit("update:item_sync", this.selectedTema.id);
    },

    getTemas() {
      this.loadingTema = true;
      return fetchPostagensTemas()
        .then((response) => {
          const temas = response.map((item) => {
            item.edit = false;
            return item;
          });

          this.temas = temas;
          this.loadingTema = false;
        })
        .catch(() => {
          this.loadingTema = false;
        });
    },

    addTema() {
      let tema_existe = this.temas.find(
        (item) => item.descricao === this.buscaTema
      );

      if (tema_existe) {
        this.$toast.error("Tema ja existe, selecione nas lista!");
        this.buscaTema = null;
        return;
      }

      let tema = { status: 1 };
      tema.descricao = this.buscaTema;
      tema.cor = this.tema.cor ? this.tema.cor : "accent";

      postPostagemTema(tema)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Tema criado com sucesso!");

            this.getTemas().then(() => {
              this.selectedTema = this.temas.find(
                (i) => i.descricao === tema.descricao
              );
            });

            this.$refs.temaBox.isFocused = false;
          }
        })
        .catch(() => {});
    },

    updateTema(item) {
      let tema = this.tema;
      tema.id = item.id;
      tema.descricao = item.descricao;
      tema.cor = this.tema.cor;

      putPostagemTema(tema.id, tema)
        .then((response) => {
          if (response.status === 201) {
            item.edit = false;
            this.$toast.success("Tema atualizado com sucesso!");
            this.getTemas();
          }
        })
        .catch(() => {});
    },

    updateAddTemaColor(item) {
      if (this.selectedCorEdit.hexa !== "#FF0000FF") {
        item.cor = this.selectedCorEdit.hex;
      }
    },
  },

  async mounted() {
    await this.getTemas();
    if (this.edit && this.temaProp) {
      this.selectedTema = { ...this.temaProp };
    }
  },
};
</script>

<style lang="scss"></style>
