var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"temaBox",attrs:{"items":_vm.temas,"search-input":_vm.buscaTema,"disabled":_vm.loadingTema,"rules":_vm.formRules,"label":"Tema","item-text":"descricao","item-value":"id","clearable":"","return-object":"","dense":""},on:{"update:searchInput":function($event){_vm.buscaTema=$event},"update:search-input":function($event){_vm.buscaTema=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTema.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(!_vm.buscaTema)?_c('v-list-item',[_vm._v(" nenhum tema encontrado ")]):_c('v-list-item',{staticClass:"my-n2 py-1",on:{"click":_vm.addTema}},[_c('span',{staticClass:"caption mr-3"},[_vm._v("Clique para adicionar: ")]),_c('v-menu',{attrs:{"offset-x":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"rounded-circle mr-2",attrs:{"label":"","color":_vm.tema.cor ? _vm.tema.cor : 'accent',"height":"20px","width":"20px"},on:{"click":function($event){$event.stopPropagation();}}},'v-sheet',attrs,false),on))]}}])},[_c('v-color-picker',{attrs:{"hide-canvas":"","hide-sliders":"","show-swatches":"","hide-inputs":"","mode":"hexa"},model:{value:(_vm.selectedCor),callback:function ($$v) {_vm.selectedCor=$$v},expression:"selectedCor"}})],1),_vm._v(" "+_vm._s(_vm.buscaTema)+" ")],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-sheet',{staticClass:"rounded-circle mr-2",attrs:{"label":"","color":item.cor,"height":"20px","width":"20px"}}),_vm._v(" "+_vm._s(item.descricao)+" ")]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(item.edit)?_c('v-text-field',{attrs:{"color":item.cor,"append-icon":"mdi-content-save"},on:{"click":function($event){$event.stopPropagation();},"click:append":function($event){return _vm.updateTema(item)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"rounded-circle mr-2",attrs:{"label":"","color":item.cor,"height":"20px","width":"20px"},on:{"click":function($event){$event.stopPropagation();}}},'v-sheet',attrs,false),on))]}}],null,true)},[_c('v-color-picker',{attrs:{"hide-canvas":"","show-swatches":"","hide-sliders":"","hide-inputs":"","mode":"hexa"},on:{"update:color":function($event){return _vm.updateAddTemaColor(item)}},model:{value:(_vm.selectedCorEdit),callback:function ($$v) {_vm.selectedCorEdit=$$v},expression:"selectedCorEdit"}})],1)]},proxy:true}],null,true),model:{value:(item.descricao),callback:function ($$v) {_vm.$set(item, "descricao", $$v)},expression:"item.descricao"}}):[_c('v-sheet',{staticClass:"rounded-circle mr-2",attrs:{"label":"","color":item.cor,"height":"20px","width":"20px"}}),_vm._v(" "+_vm._s(item.descricao)+" ")],_c('v-spacer'),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();item.edit = !item.edit}}},[(item.edit)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}]),model:{value:(_vm.selectedTema),callback:function ($$v) {_vm.selectedTema=$$v},expression:"selectedTema"}})}
var staticRenderFns = []

export { render, staticRenderFns }